import React from 'react';
import SvgColor from '@minimal-theme/components/svg-color';
import { NAV, HEADER } from '@minimal-theme/layouts/config-layout';
import NavHorizontal from '@minimal-theme/layouts/dashboard/nav-horizontal';
import Logo from '@minimal-theme/components/logo';
import { bgBlur } from '@minimal-theme/theme/css';
import { Help, SupportAgent } from '@mui/icons-material';
import {
  Box,
  Chip,
  IconButton,
  Stack,
  styled,
  useMediaQuery
} from '@mui/material';
import { ADMIN_FALLBACK_STORAGE_ID, ROLES } from '@rc/admin/constants';
import { decodeJwt } from '@rc/admin/utils/providers/auth-provider';
import {
  AppBarClasses,
  LoadingIndicator,
  LoadingIndicatorClasses,
  AppBar as RaAppBar,
  usePermissions,
  useSidebarState,
  useTranslate
} from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { useOffSetTop } from '../hooks';
import UserMenu from './UserMenu';
import { useLayoutContext } from '../context';
import { Iconify, OrgSelector } from '../components';

const AppBar = props => {
  const t = useTranslate();
  const navigate = useNavigate();
  const { permissions } = usePermissions();
  const [, setIsSidebarOpen] = useSidebarState();
  const { navType } = useLayoutContext();

  const isAdmin = permissions?.includes(ROLES.ADMIN);
  const isLarge = useMediaQuery(theme => theme.breakpoints.up('lg'));
  const offset = useOffSetTop(HEADER.H_DESKTOP);
  const offsetTop = offset && navType !== 'horizontal';

  return (
    <>
      <StyledRaAppBar
        className={classes.appBar}
        {...props}
        navType={navType}
        offsetTop={offsetTop}
        toolbar={
          <>
            {navType === 'horizontal' && <Logo />}
            {!isLarge && navType !== 'horizontal' && (
              <IconButton onClick={() => setIsSidebarOpen(curr => !curr)}>
                <SvgColor src='/images/ic_menu_item.svg' />
              </IconButton>
            )}

            <OrgSelector />

            <Stack
              flexGrow={1}
              direction='row'
              alignItems='center'
              justifyContent='flex-end'
              spacing={{ xs: 0.5, sm: 1 }}
            >
              {isAdmin && <LoadingIndicator sx={{ display: 'flex' }} />}
              <IconButton
                title={t('action.help')}
                sx={{ p: theme => theme.spacing(0.5) }}
              >
                <Iconify
                  icon='solar:question-circle-linear'
                  width={28}
                  sx={{
                    color: theme =>
                      theme.palette.mode === 'dark' ? '#fff' : '#000'
                  }}
                />
              </IconButton>
              <UserMenu />
            </Stack>
          </>
        }
        userMenu={false}
        color={'inherit'}
        elevation={1}
        enableColorOnDark
      >
        <Box className={classes.content}>
          {null !== window.localStorage.getItem(ADMIN_FALLBACK_STORAGE_ID) && (
            <Chip
              icon={<SupportAgent />}
              color={'error'}
              label={t('action.exit_administration')}
              onClick={() => {
                const { token, data } = JSON.parse(
                  window.localStorage.getItem(ADMIN_FALLBACK_STORAGE_ID)
                );
                const decodedToken = decodeJwt(token);

                /** Restore admin user */
                window.localStorage.setItem('auth', token);
                window.localStorage.setItem(
                  'permissions',
                  JSON.stringify(decodedToken.roles)
                );
                window.localStorage.setItem('user', JSON.stringify(data));
                window.localStorage.removeItem(ADMIN_FALLBACK_STORAGE_ID);

                navigate('/');
                window.location.reload();
              }}
            />
          )}
        </Box>
      </StyledRaAppBar>
      {navType === 'horizontal' && <NavHorizontal />}
    </>
  );
};

const PREFIX = 'AppBar';

const classes = {
  appBar: `${PREFIX}-appBar`,
  content: `${PREFIX}-content`,
  logoImage: `${PREFIX}-logoImage`,
  title: `${PREFIX}-title`
};

const StyledRaAppBar = styled(RaAppBar, {
  shouldForwardProp: prop => prop !== 'navType' && prop !== 'offsetTop'
})(({ theme, navType, offsetTop }) => ({
  height: HEADER.H_MOBILE,
  zIndex: theme.zIndex.appBar + 1,
  ...bgBlur({
    color: theme.palette.background.default
  }),
  transition: theme.transitions.create(['height'], {
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
    height: HEADER.H_DESKTOP,
    ...(offsetTop && {
      height: HEADER.H_DESKTOP_OFFSET
    }),
    ...(navType === 'horizontal' && {
      width: '100%',
      backgroundColor: theme.palette.background.default,
      height: HEADER.H_DESKTOP_OFFSET,
      borderBottom: `dashed 1px ${theme.palette.divider}`
    }),
    ...(navType === 'mini' && {
      width: `calc(100% - ${NAV.W_MINI + 1}px)`
    })
  },
  [`& .${AppBarClasses.toolbar}`]: {
    // ...bgBlur({
    //   color: theme.palette.background.default
    // }),
    minHeight: theme.appBar?.height
  },
  [`& .${AppBarClasses.menuButton}`]: {
    display: 'none'
  },
  [`& .${classes.logoImage}`]: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1.5)
  },
  [`& .${AppBarClasses.title}`]: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginLeft: 120,
    fontSize: theme.typography.h4.fontSize
  },
  [`& .${LoadingIndicatorClasses.loader}, & .${LoadingIndicatorClasses.loadedIcon}`]:
    {
      padding: 0,
      marginRight: theme.spacing(1.5),
      color:
        theme.palette.mode === 'dark'
          ? theme.palette.common.white
          : theme.palette.common.black
    }
}));

export default AppBar;
