import { Tune as TuneIcon, Search as SearchIcon } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TextField as MuiTextField,
  Stack
} from '@mui/material';
import { useState, useCallback, useEffect } from 'react';
import { useTranslate } from 'react-admin';
import { debounce } from 'lodash';

/**
 * @typedef {import("@rc/admin/hooks/domain/organisation/useOrgSelector.js").FilterOptions} Options
 *
 * @typedef {Object} OrgSearchBarProps
 * @property {string} search
 * @property {Options} filterOptions
 * @property {React.Dispatch<React.SetStateAction<string>>} setSearch
 * @property {React.Dispatch<React.SetStateAction<Options>} setFilterOptions
 */

/**
 * @param {import('@mui/material').StackProps & OrgSearchBarProps} props
 */
export const OrgSearchBar = props => {
  const { search, filterOptions, setSearch, setFilterOptions, ...rest } = props;

  const t = useTranslate();

  const [filterMenuAnchorEl, setFilterMenuAnchorEl] = useState(null);
  const [inputValue, setInputValue] = useState(search);

  // Create debounced search function that only triggers after 300ms of inactivity
  const debouncedSetSearch = useCallback(
    debounce(value => {
      setSearch(value);
    }, 300),
    [setSearch]
  );

  // Update local input immediately for responsive UI
  const handleSearchChange = e => {
    const value = e.target.value;
    setInputValue(value);
    debouncedSetSearch(value);
  };

  // Sync input value with search prop when it changes externally
  useEffect(() => {
    setInputValue(search);
  }, [search]);

  const handleFilterMenuOpen = event => {
    setFilterMenuAnchorEl(event.currentTarget);
  };

  const handleFilterMenuClose = () => {
    setFilterMenuAnchorEl(null);
  };

  const handleFilterOptionChange = option => {
    setFilterOptions(prevOptions => ({
      ...prevOptions,
      [option]: !prevOptions[option]
    }));
  };

  return (
    <Stack
      direction='row'
      alignItems='center'
      justifyContent='flex-end'
      {...rest}
    >
      <MuiTextField
        value={inputValue}
        onChange={handleSearchChange}
        id='org-search'
        label='Search'
        size='small'
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <SearchIcon fontSize='medium' />
            </InputAdornment>
          )
        }}
      />
      <Box ml={1}>
        <IconButton
          variant='outlined'
          size='small'
          onClick={handleFilterMenuOpen}
          title='Refine search'
        >
          <TuneIcon />
        </IconButton>
        <Menu
          anchorEl={filterMenuAnchorEl}
          open={Boolean(filterMenuAnchorEl)}
          onClose={handleFilterMenuClose}
        >
          <MenuItem>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterOptions.name}
                  onChange={() => handleFilterOptionChange('name')}
                  disabled
                />
              }
              label={t('components.switchTeam.search_filters.name')}
            />
          </MenuItem>
          <MenuItem>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterOptions.defaultUserFullname}
                  onChange={() =>
                    handleFilterOptionChange('defaultUserFullname')
                  }
                />
              }
              label={t(
                'components.switchTeam.search_filters.defaultUserFullname'
              )}
            />
          </MenuItem>
          <MenuItem>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterOptions.defaultUserUsername}
                  onChange={() =>
                    handleFilterOptionChange('defaultUserUsername')
                  }
                />
              }
              label={t(
                'components.switchTeam.search_filters.defaultUserUsername'
              )}
            />
          </MenuItem>
        </Menu>
      </Box>
    </Stack>
  );
};
