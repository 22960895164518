export const createIRI = (resource, originId) => `/api/${resource}/${originId}`;

export const axiosDataToRaRecord = (data, mapFn = data => data) => {
  return mapFn({
    ...data,
    originId: String(data.id)
  });
};

/**
 * 
 * @param {string} uuid 
 * @returns {string}
 * 
 * @example
 * uuidToOriginId('/api/organisations/1') // 1
 */
export function uuidToOriginId(uuid) {
  if (!uuid) {
    return uuid;
  }

  return uuid.split('/').pop();
}