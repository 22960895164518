import { useParams } from 'react-router';
import { uuidToOriginId } from '../utils';

export const useIdParam = () => {
  const { id: routeId } = useParams();
  const id = decodeURIComponent(routeId ?? '');
  const originId = uuidToOriginId(id);

  return { id, originId };
};
