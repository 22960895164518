import { ROLES } from '@rc/admin/constants';
import { useCallback } from 'react';
import { useIsRole } from '../useIsRole';

/**
 * Returns the authenticated user's name if the provided organisation is "default", Otherwise returns the original organisation name.
 * @param {Object} organisation
 * @returns {string}
 */
export const useGetOrganisationName = () => {
  const { result: isAdmin } = useIsRole(ROLES.ADMIN);

  const getName = useCallback(
    organisation => {
      if (!organisation) {
        return '';
      } else if (!organisation.isDefault) {
        return organisation.name;
      }
      const { defaultUserName, name, originId } = organisation;
      const userPrefix = defaultUserName ? `${defaultUserName}'s ` : '';
      const originPostfix = isAdmin ? ` (${originId})` : '';
      return `${userPrefix}${name}${originPostfix}`;
    },
    [isAdmin]
  );

  return getName;
};
