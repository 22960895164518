import { useOrgContext } from '@rc/admin/context';
import useMercureSubscription from '@rc/admin/moduleOverrides/useMercureSubscription';
import { uuidToOriginId } from '@rc/admin/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGetList, usePaginationState } from 'react-admin';

const defaultPaginationState = { perPage: 10, page: 1 };

/**
 * @typedef {Object} FilterOptions
 * @property {boolean} name
 * @property {boolean} defaultUserFullname
 * @property {boolean} defaultUserUsername
 */

export const useOrgSelector = () => {
  const {
    user,
    orgState,
    organisation: currentOrganisation,
    team: currentTeam,
    isLoading,
    submit
  } = useOrgContext();

  const [tempOrg, setTempOrg] = useState(() => ({ ...orgState }));
  const [search, setSearch] = useState('');
  const [filterOptions, setFilterOptions] = useState({
    name: true,
    defaultUserFullname: false,
    defaultUserUsername: false
  });

  useEffect(() => {
    setTempOrg({ ...orgState });
  }, [orgState]);

  const _organisationsPagination = usePaginationState(defaultPaginationState);
  const teamsPagination = usePaginationState(defaultPaginationState);

  const organisationsPagination = useMemo(
    () => ({
      ..._organisationsPagination,
      setPage: page => {
        _organisationsPagination.setPage(page);
        teamsPagination.setPage(1);
      }
    }),
    [organisationsPagination, teamsPagination]
  );

  const {
    data: organisations,
    isLoading: isOrganisationsLoading,
    refetch: refetchOrganisations,
    total: organisationsTotal
  } = useGetList(
    'organisations',
    {
      pagination: organisationsPagination,
      filter: {
        currentOrg: uuidToOriginId(orgState.organisationId),
        search: search ?? undefined,
        filterOptions: search ? filterOptions : undefined
      }
    },
    {
      enabled: !!user
    }
  );

  useEffect(() => {
    if (!organisations) {
      return;
    }

    const hasSelectedOrganisation = organisations.some(
      organisation => organisation.id === tempOrg.organisationId
    );

    if (!hasSelectedOrganisation) {
      const hasCurrentOrganisation = organisations.some(
        organisation => organisation.id === currentOrganisation?.id
      );

      setTempOrg({
        organisationId: hasCurrentOrganisation
          ? currentOrganisation?.id
          : undefined,
        teamId: hasCurrentOrganisation ? currentTeam?.id : undefined
      });
    }
  }, [organisations]);

  const {
    data: teams,
    isLoading: isTeamsLoading,
    refetch: refetchTeams,
    total: teamsTotal
  } = useGetList(
    'teams',
    {
      pagination: teamsPagination,
      filter: {
        currentOrg: uuidToOriginId(tempOrg.organisationId),
        currentTeam: uuidToOriginId(orgState.teamId)
      }
    },
    { enabled: !!user && !!tempOrg.organisationId }
  );

  useMercureSubscription('organisations', orgState.organisationId, {
    onReceived: () => refetchOrganisations()
  });
  useMercureSubscription('teams', orgState.teamId, {
    onReceived: () => refetchTeams()
  });

  const handleOrganisationChange = useCallback(
    nextOrganisation => {
      if (nextOrganisation !== null) {
        setTempOrg(curr => ({
          organisationId: nextOrganisation.id,
          teamId:
            nextOrganisation.team.find(
              teamId =>
                teamId === curr.teamId ||
                teamId === currentTeam?.id ||
                // Select the organisation's default team
                teams?.some(team => team.id === teamId && team.isDefault)
            ) || nextOrganisation.team[0]
        }));
      }
    },
    [currentTeam, teams]
  );

  const handleTeamChange = useCallback(nextTeam => {
    if (nextTeam !== null) {
      setTempOrg({
        organisationId: nextTeam.organisation,
        teamId: nextTeam.id
      });
    }
  }, []);

  const handleClose = useCallback(() => {
    setTempOrg({
      organisationId: currentOrganisation?.id,
      teamId: currentTeam?.id
    });

    organisationsPagination.setPage(1);
  }, [currentOrganisation, currentTeam]);

  const handleAccept = useCallback(() => submit(tempOrg), [submit, tempOrg]);

  return {
    isLoading,
    currentOrganisation,
    currentOrganisationId: orgState.organisationId,
    selectedOrganisationId: tempOrg.organisationId,
    organisations: organisations || [],
    isOrganisationsLoading,
    organisationsPagination,
    organisationsTotal,
    currentTeam,
    currentTeamId: orgState.teamId,
    selectedTeamId: tempOrg.teamId,
    teams: teams || [],
    isTeamsLoading,
    teamsPagination,
    teamsTotal,
    search,
    filterOptions,
    handleClose,
    handleAccept,
    handleOrganisationChange,
    handleTeamChange,
    setSearch,
    setFilterOptions
  };
};
