import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useGetOne } from 'react-admin';
import { useNavigate } from 'react-router';
import { ORG_LOCAL_STORAGE_KEY } from '../constants';
import { useUser } from '../hooks';

/**
 * @typedef {{organisationId: string, teamId: string}} OrgContextState
 * @typedef {{ submit: (state: OrgContextState) => void,
 *      organisation: import('react-admin').RaRecord,
 *      team: import('react-admin').RaRecord,
 *      isLoading: boolean,
 *      user: any,
 *      orgState: OrgContextState,
 *      organisationId: string,
 *      teamId: string
 *    } & OrgContextState} OrgContextValue
 *
 * @type {import('react').Context<OrgContextValue>}
 */
const OrgContext = createContext();

export const OrgContextProvider = props => {
  const { children } = props;
  const navigate = useNavigate();
  const { user } = useUser();

  /**
   * @type {[OrgContextState, OrgContextState => void]}
   */
  const [orgState, setOrgState] = useState({
    organisationId: null,
    teamId: null
  });

  const { data: organisation, isLoading: isOrganisationLoading } = useGetOne(
    'organisations',
    { id: orgState.organisationId },
    {
      enabled: !!orgState.organisationId
    }
  );

  const { data: team, isLoading: isTeamLoading } = useGetOne(
    'teams',
    { id: orgState.teamId },
    {
      enabled: !!orgState.teamId
    }
  );

  const submit = useCallback(
    newOrg => {
      setOrgState(curr => {
        const hasChanged =
          curr.organisationId &&
          curr.organisationId !== newOrg.organisationId &&
          curr.teamId !== newOrg.teamId;

        if (hasChanged) {
          navigate('/');
        }

        return newOrg;
      });

      window.localStorage.setItem(
        ORG_LOCAL_STORAGE_KEY,
        JSON.stringify(newOrg)
      );
    },
    [navigate, setOrgState]
  );

  // Initialize with default value
  useEffect(() => {
    if (!user) {
      return;
    }

    const storedOrg = getOrgFromLocalStorage();

    let org = storedOrg ?? {
      organisationId: user.defaultOrganisation,
      teamId: user.defaultTeam
    };

    submit(org);
  }, [submit, user]);

  const value = useMemo(() => {
    return {
      orgState,
      // Expose organisationId and teamId
      ...orgState,
      user,
      organisation,
      team,
      isLoading: isOrganisationLoading || isTeamLoading,
      submit
    };
  }, [
    user,
    isOrganisationLoading,
    isTeamLoading,
    orgState,
    organisation,
    team,
    submit
  ]);

  return <OrgContext.Provider value={value}>{children}</OrgContext.Provider>;
};

const getOrgFromLocalStorage = () => {
  const storedOrg = window.localStorage.getItem(ORG_LOCAL_STORAGE_KEY);

  try {
    const org = JSON.parse(storedOrg);

    const isValidOrganisationId = org.hasOwnProperty('organisationId');
    const isValidTeamId = org.hasOwnProperty('teamId');

    if (isValidOrganisationId && isValidTeamId) {
      return org;
    }
  } catch (error) {
    return null;
  }

  return null;
};

export const useOrgContext = () => useContext(OrgContext);
