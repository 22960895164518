import { UnfoldMoreOutlined as UnfoldMoreIcon } from '@mui/icons-material';
import {
  Box,
  Divider,
  Grid,
  LinearProgress,
  Stack,
  styled,
  tablePaginationClasses,
  Typography,
  useMediaQuery
} from '@mui/material';
import { useGetOrganisationName, useOrgSelector } from '@rc/admin/hooks/domain';
import {
  DialogMenuItem,
  DialogMenuItemClasses
} from '@rc/admin/layout/MenuItem';
import { useMemo } from 'react';
import {
  Datagrid,
  Pagination,
  RecordContextProvider,
  ResourceContext,
  TextField,
  useTranslate
} from 'react-admin';
import { OrganisationField } from '../OrganisationField';
import { OrgSearchBar } from './OrgSearchBar';
import { LoadingBox } from '@rc/admin/components/ui';

/**
 * @param {import('@mui/material').BoxProps} props.BoxProps
 */
export const OrgSelector = props => {
  const t = useTranslate();
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const getOrganisationName = useGetOrganisationName();

  const {
    isLoading,
    currentOrganisation,
    currentOrganisationId,
    selectedOrganisationId,
    organisations,
    isOrganisationsLoading,
    organisationsPagination,
    organisationsTotal,
    currentTeam,
    currentTeamId,
    selectedTeamId,
    teams,
    isTeamsLoading,
    teamsPagination,
    teamsTotal,
    search,
    filterOptions,
    handleClose,
    handleAccept,
    handleOrganisationChange,
    handleTeamChange,
    setSearch,
    setFilterOptions
  } = useOrgSelector();

  const organisationsWithName = useMemo(
    () =>
      organisations?.map(organisation => ({
        ...organisation,
        customName: getOrganisationName(organisation)
      })),
    [getOrganisationName, organisations]
  );

  return (
    <Root {...props} id='team-switcher'>
      <DialogMenuItem
        rightIcon={<UnfoldMoreIcon className={classes.rightIcon} />}
        primaryText={
          isLoading ? (
            <LinearProgress />
          ) : (
            <Box className={classes.label}>
              <Typography variant={'body1'} className={classes.organisation}>
                {currentOrganisation && (
                  <RecordContextProvider value={currentOrganisation}>
                    <OrganisationField label='' sx={{ fontSize: '0.75rem' }} />
                  </RecordContextProvider>
                )}
              </Typography>
              <Typography variant={'body2'} color={'textSecondary'}>
                {currentTeam?.name}
              </Typography>
            </Box>
          )
        }
        title={t('components.switchTeam.access_title')}
        dialogTitle={
          <Stack spacing={1}>
            <Stack
              width='100%'
              direction={{ xs: 'column', md: 'row' }}
              justifyContent='space-between'
              alignItems={{ xs: 'flex-start', md: 'center' }}
              spacing={2}
            >
              <Typography>{t('components.switchTeam.dialog_title')}</Typography>
              <OrgSearchBar
                search={search}
                setSearch={setSearch}
                filterOptions={filterOptions}
                setFilterOptions={setFilterOptions}
                width='100%'
                maxWidth={{ xs: '100%', md: '17.5em' }}
              />
            </Stack>
            <Divider />
          </Stack>
        }
        onClose={handleClose}
        onAccept={handleAccept}
        canAccept={selectedOrganisationId && selectedTeamId}
      >
        <Grid container className={classes.root} spacing={1}>
          <Grid item xs={12} md={6}>
            <ResourceContext.Provider value='organisations'>
              <DataList
                data={organisationsWithName}
                total={organisationsTotal}
                onSelect={handleOrganisationChange}
                selectedId={selectedOrganisationId}
                currentId={currentOrganisationId}
                pagination={organisationsPagination}
                isLoading={isOrganisationsLoading}
              >
                <TextField
                  label={t('resources.organisations.name', {
                    smart_count: 1
                  })}
                  source='customName'
                />
              </DataList>
            </ResourceContext.Provider>
          </Grid>
          {isSmall && (
            <Grid item xs={12}>
              <Divider />
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <ResourceContext.Provider value='teams'>
              <DataList
                data={teams}
                total={teamsTotal}
                onSelect={handleTeamChange}
                selectedId={selectedTeamId}
                currentId={currentTeamId}
                pagination={teamsPagination}
                isLoading={isTeamsLoading}
                empty={!selectedOrganisationId ? null : undefined}
              >
                <TextField
                  label={t('resources.teams.name', { smart_count: 1 })}
                  source='name'
                />
              </DataList>
            </ResourceContext.Provider>
          </Grid>
        </Grid>
      </DialogMenuItem>
    </Root>
  );
};

const sort = { field: 'id', order: 'DESC' };

/**
 *
 * @param {import('react-admin').DatagridProps} props
 * @returns
 */
const DataList = props => {
  const {
    data,
    total,
    onSelect,
    selectedId,
    currentId,
    children,
    pagination,
    isLoading,
    ...rest
  } = props;

  if (isLoading) {
    return <LoadingBox />;
  }

  return (
    <>
      <Datagrid
        className={classes.dataGrid}
        data={data}
        total={total}
        sort={sort}
        rowClick={id => onSelect(data.find(record => record.id === id))}
        rowSx={({ id }) =>
          theme => ({
            backgroundColor:
              id === selectedId
                ? theme.palette.mode === 'light'
                  ? 'rgba(0, 0, 0, 0.08)'
                  : 'rgba(255, 255, 255, 0.08)'
                : id === currentId
                ? theme.palette.mode === 'light'
                  ? 'rgba(0, 0, 0, 0.03)'
                  : 'rgba(255, 255, 255, 0.03)'
                : undefined
          })}
        bulkActionButtons={false}
        isLoading={isLoading}
        {...rest}
      >
        {children}
      </Datagrid>
      <Pagination
        {...pagination}
        rowsPerPageOptions={[]}
        isLoading={isLoading}
        total={total}
        sx={{
          display: total === 0 || total < pagination.perPage ? 'none' : 'block',
          [`.${tablePaginationClasses.displayedRows}`]: {
            display: 'none'
          }
        }}
      />
    </>
  );
};

const PREFIX = 'SwitchTeam';

const classes = {
  form: `${PREFIX}-form`,
  buttonGroupSubtitle: `${PREFIX}-buttonGroupSubtitle`,
  buttonGroup: `${PREFIX}-buttonGroup`,
  button: `${PREFIX}-button`,
  organisationButton: `${PREFIX}-organisationButton`,
  teamButton: `${PREFIX}-teamButton`,
  organisation: `${PREFIX}-organisation`,
  leftIcon: `${PREFIX}-leftIcon`,
  rightIcon: `${PREFIX}-rightIcon`,
  selector: `${PREFIX}-selector`
};

const Root = styled(Box)(({ theme }) => ({
  minWidth: 200,

  [`& .${classes.form}`]: {
    display: 'flex',
    flexWrap: 'wrap'
  },

  [`& .${classes.buttonGroupSubtitle}`]: {
    marginBottom: theme.spacing(1)
  },

  [`& .${classes.buttonGroup}`]: {
    marginBottom: theme.spacing(2)
  },

  [`& .${classes.button}`]: {
    '&.MuiButtonBase-root': {
      display: 'flex',
      columnGap: theme.spacing(1),
      justifyContent: 'space-between',
      minWidth: '170px'
    }
  },

  [`& .${classes.organisationButton}`]: {},

  [`& .${classes.teamButton}`]: {},

  [`& .${classes.organisation}`]: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'block'
  },

  [`& .${DialogMenuItemClasses.menuItem}`]: {
    marginBottom: 0,
    minHeight: 60
  },

  [`& .${classes.leftIcon}`]: {
    color: theme.palette.primary[500]
  },

  [`& .${classes.rightIcon}`]: {},

  [`& .${classes.selector}`]: {
    display: 'flex',
    flexWrap: 'wrap',

    [`& .${classes.dataGrid}`]: {
      cursor: 'pointer'
    }
  }
}));

OrgSelector.defaultProps = {};
